<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <v-card class="mt-8">
      <div class="d-md-flex d-block pa-8 align-center justify-space-between">
        <div>
          <v-img
            v-if="detail.ranking === 1"
            width="40"
            src="@/assets/icons/multi-color/rank-1.svg"
          ></v-img>
          <v-img
            v-else-if="detail.ranking === 2"
            width="40"
            src="@/assets/icons/multi-color/rank-2.svg"
          ></v-img>
          <v-img
            v-else-if="detail.ranking === 3"
            width="40"
            src="@/assets/icons/multi-color/rank-3.svg"
          ></v-img>
          <h2 v-else>
            {{ detail.ranking }}
          </h2>
        </div>
        <div>
          <div class="d-md-flex align-center">
            <div class="me-2">
              <v-img
                v-if="detail.photo !== null"
                width="40"
                :src="detail.photo"
              ></v-img>
              <v-img
                v-else
                width="40"
                src="@/assets/images/avatars/ari.png"
              ></v-img>
            </div>
            <div>{{ detail.name }}</div>
          </div>
        </div>
        <div>
          <div class="text-center">
            <h3 class="warning--text">
              {{ detail.total_point }}
            </h3>
            <span>Total Nilai</span>
          </div>
        </div>
        <div>
          <div class="text-center">
            <h3 class="success--text">
              {{ detail.true }}
            </h3>
            <span>Jawaban Benar</span>
          </div>
        </div>
        <div>
          <div class="text-center">
            <h3 class="error--text mb-0">
              {{ detail.false }}
            </h3>
            <span>Jawaban Salah</span>
          </div>
        </div>
        <div>
          <div class="text-center">
            <h3 class="black--text">
              {{ detail.start }} - {{ detail.end }}
            </h3>
            <span>Waktu Pengerjaan</span>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-text>
        <v-card
          v-for="question,index in questions"
          :key="index"
          outlined
          class="mb-2"
        >
          <v-expansion-panels>
            <v-expansion-panel class="elevation-0">
              <v-expansion-panel-header class="elevation-0">
                <v-row class="align-center">
                  <v-col md="1">
                    Soal No {{ index + 1 }}
                  </v-col>
                  <v-col md="6">
                    <p
                      class="d-md-flex align-center"
                      v-html="question.name"
                    ></p>
                  </v-col>
                  <v-col md="2">
                    <v-chip
                      v-if="question.pivot.is_answer === '1'"
                      class="v-chip-light-bg success--text"
                    >
                      <v-img
                        width="20"
                        src="@/assets/icons/color/true.svg"
                      ></v-img>
                      <span class="ml-2">Benar</span>
                    </v-chip>
                    <v-chip
                      v-else
                      class="v-chip-light-bg error--text"
                    >
                      <v-img
                        width="20"
                        src="@/assets/icons/color/false.svg"
                      ></v-img>
                      <span class="ml-2">Salah</span>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex offset-1">
                  <div>
                    <h4>Jawaban Benar</h4>
                    <span>{{ question.answer_cbt.answer }}</span>
                  </div>
                  <div class="offset-1">
                    <h4>Jawaban Saya</h4>
                    <span>{{ question.pivot.answer }}</span>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'CbtResultDetail',
  components: {},
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
      cbt_uuid: '',
      user_uuid: '',
      detail: {},
      questions: [],
    }
  },
  mounted() {
    this.getResultDetail(this.$route.params.cbtUuid, this.$route.params.userUuid)
  },
  methods: {
    async getResultDetail() {
      await this.$services.ApiServices.getMultiple(
        'answercbt',
        this.$route.params.cbtUuid,
        this.$route.params.userUuid,
      ).then(({ data }) => {
        console.log(data.data)
        this.questions = data.data
        this.detail = data.meta
      })
    },
    returnBack() {
      return this.$router.back()
    },
  },
}
</script>

<style>
</style>
